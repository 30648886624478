import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './modules/layout/layout.module';
import { RoutesModule } from './routes/routes.module';
import localeES from '@angular/common/locales/es';
import { SettingsService } from './core/settings/settings.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { APP_ICONS } from './models/configuration/app-icons-config';
import { PAGE_TITLES } from './models/configuration/page-titles-config';
import { AuthWrapperService } from './services/auth/auth.wrapper.service';
import { registerLocaleData, KeyValuePipe } from '@angular/common';
import { AppConfig, BasCommonModule, SharedModule } from 'bas-common';

registerLocaleData(localeES, 'es');

const configInitializer = (appConfig: SettingsService) => {
    return () => {
        appConfig.loadAppConfig();
    };
};

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        BasCommonModule
    ],
    providers: [
        KeyValuePipe,
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: configInitializer,
            multi: true,
            deps: [
                SettingsService
            ]
        },
        {
            provide: AppConfig,
            useValue: { apiUrl: environment.apiUrl }
        },
        { provide: 'AuthService', useClass: AuthWrapperService },
        { provide: 'AppIconsConfig', useValue: APP_ICONS },
        { provide: 'PageTitles', useValue: PAGE_TITLES },

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
