import { PageTitleConfig } from "bas-common";

export const PAGE_TITLES: PageTitleConfig = {
    // Auth
    '/auth/inicio-sesion': { title: 'Web de Administración' },
    '/auth/registro': { title: 'Registro' },
    '/auth/recuperar-contraseña': { title: 'Recuperación de Contraseña' },
    '/auth/confirmar-correo': { title: 'Confirmación de Correo Electrónico' },
    // Inicio
    '/inicio': { title: 'Inicio' },
    // Afiliados
    '/clientes': {
        title: 'Clientes',
        // description: 'Consulte y edite la información de clientes.'
    },
    // Planes
    '/planes': {
        title: 'Planes',
        // description: 'Consulte y edite la información de planes.'
    },
    // Usuarios
    '/usuarios': {
        title: 'Usuarios',
        // description: 'Consulte y edite la información de usuarios.'
    },
    // Configuración
    '/administracion/configuracion': {
        title: 'Configuración',
        // description: 'Consulte y edite la configuración del sistema.'
    }
};