import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthenticationToken, IAuthService, Role } from 'bas-common';


@Injectable({
  providedIn: 'root'
})
export class AuthWrapperService implements IAuthService {

  constructor(private authService: AuthService) {}

  refreshToken(): any {
    return this.authService.refreshToken();
  }

  getTokens(): AuthenticationToken | null {
    return this.authService.getTokens();
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  redirectToLogin(): any {
    return this.authService.redirectToLogin();
  }

  getRole(): Role {
    return this.authService.getRole();
  }
}
