import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { AuthService } from '../services/auth/auth.service';
import { skipWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService, SharedModule } from 'bas-common';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes),
    ],
    exports: [
        RouterModule
    ],
})


export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService, auth: AuthService) {
        const login = auth.loggedIn$.pipe(skipWhile(data => !data));
        const jsonmenu = menuService.getMenuJson();
        const combined = combineLatest([login, jsonmenu]);
        combined.subscribe(data => {
            if (data[0] && menuService.getMenu().length === 0) {
                menuService.addMenu(data[1].menu);
            }
            else {
                menuService.setMenu([]);
            }
        });
    }
}