import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationToken, RefreshToken, Role, ToasterNotificationService, Token, User } from 'bas-common';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';

const swal = require('sweetalert');
const API_URL = `${environment.apiUrl}/Authentication`;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private loggedIn = false;
    _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.loggedIn);
    loggedIn$ = this._loggedIn.asObservable();
    private role: Role = null;

    private _username: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    username$ = this._username.asObservable();

    static tokensStorageKey = "tokens";

    get getUsername() {
        return this._username.getValue();
    }

    get isLoggedIn(): boolean {
        return this.loggedIn;
    }

    getRole() {
        return this.role;
    }

    constructor(
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService,
        private toaster: ToasterNotificationService) {
        const tokens = this.getTokens();
        if (tokens && Date.now() / 1000 < this.getExpiration(tokens.accessToken)) {
            this.loggedIn = true;
            this.setRoleFromToken(tokens.accessToken);
            this.setUsernameFromToken(tokens.accessToken);
        }
        if (this.loggedIn) {
            router.navigate(['/inicio']);
        }
        this._loggedIn.next(this.loggedIn);
    }

    login(username: string, userpassword: string) {

        const login = {
            username,
            userpassword
        };

        this.http.post<User>(`${API_URL}`, login)
            .subscribe(user => {
                this.setTokens(user.tokens);
                this.setRoleFromToken(user.tokens.accessToken);
                this.loggedIn = true;
                this.setUsernameFromToken(user.tokens.accessToken);
                this._loggedIn.next(this.loggedIn);
                this.toaster.showToast('success', '', `Bienvenido ${username}`);
            });
    }

    logout() {
        swal({
            title: '¿Desea salir?',
            icon: 'warning',
            buttons: {
                confirm: {
                    text: 'Salir',
                    value: true,
                    visible: true,
                    className: 'bg-danger',
                    closeModal: true
                },
                cancel: {
                    text: 'Cancelar',
                    value: null,
                    visible: true,
                    closeModal: true
                }
            }
        }).then((isConfirm) => {
            if (isConfirm) {
                this.redirectToLogin();
            }
        });
    }

    
    resetPassword(username: string, email: string) {
        const resetPwd = {
            username,
            email
        };
        return this.http.post(`${API_URL}/ResetPassword`, resetPwd);
    }

    // TODO: Implementar el cambio de contraseña
    /* changePassword(userPassword: string, userPasswordNew: string) {
        const changePwd = {       
            userPassword, 
            userPasswordNew };
        return this.http.post(`${API_URL}/ChangePassword`, changePwd);
    } */

    refreshToken() {
        const tokens = this.getTokens();
        const refreshToken = {
            accessToken: tokens.accessToken,
            refreshToken: tokens.refreshToken
        };

        return this.http.post<RefreshToken>(`${API_URL}/refreshToken`, refreshToken)
            .pipe(tap((newTokens) => {
                if (tokens && tokens.accessToken) {
                    const tokens = this.getTokens();
                    tokens.accessToken = newTokens.accessToken;
                    tokens.refreshToken = newTokens.refreshToken;
                    this.setTokens(tokens);
                }
            }));
    }

    redirectToLogin() {
        this.loggedIn = false;
        this._loggedIn.next(this.loggedIn);
        this.clearUser();
        this.cookieService.deleteAll();
        this.router.navigate(['auth/inicio-sesion']);
    }

    private getExpiration(tokenAsString: string) {
        const token: Token = JSON.parse(window.atob(tokenAsString.split('.')[1]));
        return token.exp;
    }

    private setRoleFromToken(tokenAsString: string) {
        const token: Token = JSON.parse(window.atob(tokenAsString.split('.')[1]));
        this.role = new Role();
        if (Array.isArray(token.Function)) {
            token.Function.forEach(claim => {
                this.role.functions.push(claim);
            });
        }
        else if (token.Function) {
            this.role.functions.push(token.Function);
        }
    }
    //Setea el usuario conectado en el observable para mostrar en el layout
    private setUsernameFromToken(tokenAsString: string) {
        const token: Token = JSON.parse(window.atob(tokenAsString.split('.')[1]));
        this._username.next(token.unique_name);
    }

    clearUser() {
        localStorage.removeItem(AuthService.tokensStorageKey);
    }

    getTokens(): AuthenticationToken {
        let result = new AuthenticationToken();
        const currentTokens = localStorage.getItem(AuthService.tokensStorageKey);
        if (currentTokens) {
            Object.assign(result, JSON.parse(currentTokens));
        } else {
            result = null;
        }
        return result;
    }

    setTokens(tokens: AuthenticationToken) {
        let newTokens = new AuthenticationToken();
        if (tokens) {
            newTokens.accessToken = tokens.accessToken;
            newTokens.refreshToken = tokens.refreshToken;

        } else {
            newTokens = null;
        }
        localStorage.setItem(AuthService.tokensStorageKey, JSON.stringify(newTokens));
    }
}
